// const toChies = function (values) { //形参
//   if (values == "" || values == null || values == undefined) {
//     return '零';
//   }
//   var about = '';
//   var nLen = (values + '').split('.');
//   if (nLen[nLen.length - 1].length > 2) {
//     about = '约 '
//   }
//   var n = parseFloat(values).toFixed(2);


//   if (n == 0) {
//     return "零";
//   }
//   if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n))
//     return "";
//   var unit = "千百拾亿千百拾万千百拾元角分",
//     str = "";
//   n += "00";
//   var p = n.indexOf('.');
//   if (p >= 0) {
//     n = n.substring(0, p) + n.substr(p + 1, 2);
//     unit = unit.substr(unit.length - n.length);
//   }
//   for (var i = 0; i < n.length; i++) {
//     str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
//   }
//   return about + str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(
//     /(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");

// }
//时间戳转时间
const format = function (shijianchuo) { //形参
  // debugger
  if (shijianchuo == "" || shijianchuo == undefined || shijianchuo == null) {
    return "";
  }
  if (shijianchuo == "暂无") {
    return shijianchuo
  }
  //shijianchuo是整数，否则要parseInt转换
  let shijianchuoNew = shijianchuo;
  if (("" + shijianchuo).length < 13) {
    shijianchuoNew = shijianchuo * 1000
  }
  var time = new Date(shijianchuoNew);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return "" + y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (mm <
    10 ? '0' + mm : mm) + ':' + (s < 10 ? '0' + s : s);
}
//时间戳转月份
const formonth = function (shijianchuo) { //形参
  if (shijianchuo == "" || shijianchuo == undefined || shijianchuo == null) {
    return "";
  }
  //shijianchuo是整数，否则要parseInt转换
  let shijianchuoNew = shijianchuo;
  if (("" + shijianchuo).length < 13) {
    shijianchuoNew = shijianchuo * 1000
  }
  var time = new Date(shijianchuoNew);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  // var d = time.getDate();
  // var h = time.getHours();
  // var mm = time.getMinutes();
  // var s = time.getSeconds();
  return "" + y + '-' + (m < 10 ? '0' + m : m);
}
//时间戳转日期
const formatData = function (shijianchuo) { //形参
  if (shijianchuo == "" || shijianchuo == undefined || shijianchuo == null) {
    return "";
  }
  //shijianchuo是整数，否则要parseInt转换
  let shijianchuoNew = shijianchuo;
  if (("" + shijianchuo).length < 13) {
    shijianchuoNew = shijianchuo * 1000
  }
  var time = new Date(shijianchuoNew);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  return "" + y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
}

//时间戳转时间
const returnCheckMark = function (date, start, end) { //形参
  let startDate = new Date(start).getDate();
  let endDate = new Date(end).getDate();
  console.log(date);
  console.log(startDate);
  console.log(endDate);
  if (date >= startDate && date <= endDate) {
    return "——";
  }
  return "";
}
export default {
  // toChies,
  format,
  formonth,
  formatData,
  returnCheckMark
}
