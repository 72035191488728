import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'login',
		// redirect: {
		// 	name: '登录'
		// },
		isShow: true,
		component: resolve => require(['@/Section1'], resolve),
		ico: 'el-icon-edit',
		redirect: '/login',
		children: [{
			path: '/login',
			ico: 'el-icon-edit',
			component: resolve => require(['@/views/login/login'], resolve),
			name: '登录'
		}]
	},
	{
		path: '/logistics',
		name: '物流管理',
		ico: 'el-icon-s-order',
		pow:[
			"LogisticsManagement/add",
			"LogisticsManagement/importLogistics",
			"LogisticsManagement/list"
		],
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				path: '/logistics/add',
				pow:[
					"LogisticsManagement/add",
				],
				component: resolve => require(['@/views/logistics/logisticsAdd/logisticsAdd'], resolve),
				name: '物流单增加'
			}, {
				path: '/logistics/import',
				pow:[
					"LogisticsManagement/importLogistics",
				],
				component: resolve => require(['@/views/logistics/logisticsImport/logisticsImport'], resolve),
				name: '物流单导入'
			}, {
				path: '/logistics/list',
				pow:[
					"LogisticsManagement/list"
				],
				component: resolve => require(['@/views/logistics/logisticsList/logisticsList'], resolve),
				name: '物流单列表'
			}
		]
	},

	{
		path: '/index',
		name: '订单列表',
		ico: 'el-icon-s-operation',
		redirect: '/index',
		pow:[
			"Order/list"
		],
		leaf: true,
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				ico: 'el-icon-s-operation',
				path: '/index',
				pow:[
					"Order/list"
				],
				component: resolve => require(['@/views/index/index'], resolve),
				name: '订单列表'
			}
		]
	},

	{
		path: '/processed',
		name: '处理中',
		ico: 'el-icon-s-help',
		leaf: true,
		pow:[
			"Pending/list",
		],
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				path: '/processed',
				ico: 'el-icon-s-help',
				pow:[
					"Pending/list"
				],
				component: resolve => require(['@/views/processed/processed'], resolve),
				name: '处理中'
			}
		]
	},

	{
		path: '/account',
		name: '账号管理',
		pow:[
			"Admin/list",
			"Group/groupList",
			"Authority/roleList"
		],
		ico: 'el-icon-user-solid',
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				path: '/account/user',
				pow:[
					"Admin/list"
				],
				component: resolve => require(['@/views/account/user/user'], resolve),
				name: '用户管理'
			},
			{
				path: '/account/group',
				pow:[
					"Group/groupList"
				],
				component: resolve => require(['@/views/account/group/group'], resolve),
				name: '组管理'
			},
			{
				path: '/account/role',
				pow:[
					"Authority/roleList"
				],
				component: resolve => require(['@/views/account/role/role'], resolve),
				name: '权限管理'
			}
		]
	},

	{
		path: '/customer',
		name: '客户管理',
		ico: 'el-icon-s-platform',
		pow:[
			"Client/clientList"
		],
		leaf: true,
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				path: '/customer',
				pow:[
					"Client/clientList"
				],
				ico: 'el-icon-s-platform',
				component: resolve => require(['@/views/customer/customer'], resolve),
				name: '客户管理'
			}
		]
	},
	{
		path: '/product',
		name: '产品管理',
		pow:[
			"Product/list"
		],
		ico: 'el-icon-s-cooperation',
		leaf: true,
		component: resolve => require(['@/Section'], resolve),
		children: [
			{
				path: '/product',
				pow:[
					"Product/list"
				],
				ico: 'el-icon-s-cooperation',
				component: resolve => require(['@/views/product/product'], resolve),
				name: '产品管理'
			}
		]
	},

	// {
	// 	path: '/admin',
	// 	name: '管理员',
	// 	ico:'el-icon-s-help',
	// 	component: resolve => require(['@/Section'], resolve),
	// 	children: [
	// 		{
	// 			path: '/admin',
	// 			component: resolve => require(['@/views/admin/list/list'], resolve),
	// 			name: '管理员'
	// 		},
	// 		{
	// 			path: '/role',
	// 			component: resolve => require(['@/views/role/list/list'], resolve),
	// 			name: '权限'
	// 		}
	// 	]
	// },
]


/*
	isShow:是否在侧边栏显示
		 true:不显示
		 false|不写，显示
	leaf:是否有下级页面
		true:没有
		false|不写，有
 */

const router = new VueRouter({
	// mode: "hash",
	// base: '/suyuan/',

	mode: "hash",
	base: process.env.BASE_URL,
	routes
})

export default router;