<template>
  <div class="UE">
    <!--这个地方的大小是可以自己控制的-->
    <div id="editor" style="width:100%;height:380px;">
      <!-- <div ref="toolbar" class="toolbar">
        <div class="w-e-menu" style="z-index:10001;" @click="onHTML"><span :class=" isHtml==1?'bg_f1f2f7 fc_999 fw_blod':' fc_999 fw_blod' ">HTML</span></div>
      </div>
      <div ref="editor" class="text">
      </div> -->
    </div>
  </div>
</template>
<script>
  import E from 'wangeditor'
  export default {
    name: 'ue',
    props: {
      value: {
        type: String,
        default: ""
      },
    }, 
    data() {
      return {
        editor: null,
        isHtml:0,
      };
    },
    mounted() {
      this.editor = new E('#editor');
      // this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      // this.editor.customConfig.uploadImgHeaders = {} // 自定义 header
      // 自定义菜单配置
      this.editor.customConfig.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo' // 重复k
      ]
      this.editor.customConfig.uploadImgShowBase64 = true // 使用 base64 保存图片
      this.editor.create()
      console.log(this.value);
      this.editor.txt.html(this.value)
    },
    methods: {
      //获取编辑器中的内容
      getUEContent() {
        return this.editor.txt.html()
      },
      //设置内容
      setContent(content){
        this.editor.txt.html(content)
      },
      onHTML(){
        this.isHtml=this.isHtml==0?1:0;

        if(this.isHtml==1){
          let content=this.editor.txt.html();
          content=content.replace(/</g,'&lt;')
          content=content.replace(/>/g,'&gt;')
          this.editor.txt.html(content)
        }else{
          let content=this.editor.txt.html();
          content=content.replace(/&lt;/g,'<')
          content=content.replace(/&gt;/g,'>')
          this.editor.txt.html(content)
        }

      },
    },
    destroyed() {
      // 将editor进行销毁
      // this.editor.destroy();
    }
  }
</script>
<style>
  .w-e-text-container{
    height:300px;
    border: 1px solid #ccc;
  
  }
</style>
