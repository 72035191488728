<template>
  <!-- 建议不隐藏，隐藏后在一定条件下page=NaN-->
  <el-row type="flex" justify="center" class="m_t_10">
    <el-pagination
      background
      @size-change="handlePageSizeChange"
      @current-change="handleCurrentChange"
      :current-page="isNum(pagination.current)"
      :page-sizes="[10, 100]"
      :page-size="pagination.pageSize"
      :total="isNum(total)"
      layout="prev,pager,next,jumper,total,sizes"
    ></el-pagination>
  </el-row>
</template>

<script>
export default {
  props: ['total'],
  data() {
    return {
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10
      }
    };
  },
  methods: {
    //设置分页大小
    handlePageSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      if (this.$parent.getTableData == undefined) {
        this.$parent.$parent.getTableData();
      } else {
        this.$parent.getTableData();
      }
    },
    //设置页码
    handleCurrentChange(current) {
      this.pagination.current = current;
      if (this.$parent.getTableData == undefined) {
        this.$parent.$parent.getTableData();
      } else {
        this.$parent.getTableData();
      }
    },
    //total限制
    isNum(num) {
      if (num == undefined || num == "" || num == null) {
        return 0;
      }
      return num;
    }
  }
};
</script>

<style>
.el-pagination__total {
  line-height: 30px !important;
  margin-left: 20px;
}
</style>
