import axios from 'axios'
let qs = require('qs')
// import qs from 'qs'


// let BaseURL = 'http://192.168.1.194:8088/index.php/admin/'

//邓力
// let BaseURL = "http://192.168.1.118:8021/index.php/api/";
// let URL = "http://192.168.1.118:8021/"



// let BaseURL = "http://121.42.10.139:8030/index.php/api/";
// let URL = "http://121.42.10.139:8030/"

//线上服务器
let BaseURL = "https://callback.metjack.com/index.php/api/";
let URL = "https://callback.metjack.com/"


//内网
// let BaseURL = "http://192.168.1.118:8021/index.php/api/";
// let URL = "http://192.168.1.118:8021/"

// let BaseURL = "http://139.196.43.130:8001/index.php/api/";
// let URL = "http://139.196.43.130:8001/"

let sy = "logistics"


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

/**
 * 方法说明 post传参时,参数拦截修改
 * @method getToken
 * @param {参数类型} 参数名 参数说明
 * @return {返回值类型} 返回值说明
 */
function getToken(params) {
	let token = JSON.parse(localStorage.getItem(sy)).token
	axios.defaults.headers.common['token'] = token
	// axios.defaults.headers.common['token'] = '15738257957'
	// axios.defaults.headers.common['Authorization'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBRVMiLCJhdWQiOjEsImlhdCI6MTU5NjUyMzEwNiwibmJmIjoxNTk2NTIzMTA3LCJleHAiOjE1OTcxMjc5MDYsImRhdGEiOnsidXNlcl9pZCI6MSwiaXNfYWRtaW4iOjEsInVzZXJfc3lzdGVtIjoyMjJ9fQ.y0idhgRH_2abIA9x3Xjj-xJ0J3moSAKwMwQItMyYejQ"

			// console.log(qs.stringify(params))
	return qs.stringify(params)
}
/**
 * 方法说明 get传参时,参数拦截修改
 * @method getposys
 * @param {参数类型} 参数名 参数说明
 * @return {返回值类型} 返回值说明
 */
function getposys(params) {
	let token = JSON.parse(localStorage.getItem(sy)).token
	axios.defaults.headers.common['token'] = token
	// axios.defaults.headers.common['token'] = '15738257957'

	return {
		params: params
	}
}
export default {
	install(Vue) {
		Vue.prototype.sy = sy
		Vue.prototype.BaseURL = BaseURL
		Vue.prototype.imgURL = URL //+ 'upload/'  //   图片路经
		Vue.prototype.imgBaseURL = URL + 'public/upload'
		// Vue.prototype.fileBaseURL = BaseURL + 'image/uploadFile'
		// Vue.prototype.uploadImgServer = BaseURL + 'image/upload'

		Vue.prototype.axiosPost = function(urls, params, Callback) {
			// if (localStorage.getItem(sy) == null || localStorage.getItem(sy) === undefined) {
			//     this.$router.push('/')
			//     return
			// }
			// 重复提交后台验证
			// if (sign !== undefined) {
			//     axios.defaults.headers.common['sign'] = sign
			// }
			axios.post(BaseURL + urls, getToken(params))
				.then(response => {
					if (response.data.code == 501) {
						this.$message({
							showClose: true,
							message: response.data.message, //'token失效,重新登录',
							type: 'error'
						})
						this.history("/login")
						this.onLoad(false)
						return
					}

					if (response.data.code == 2) {
						this.$message({
							showClose: true,
							message: response.data.message,
							type: 'error'
						})
						this.$router.push("/")
						this.onLoad(false)
						return
					}

					// if (response.data.code != 0) {
					//     this.$message({
					//         showClose: true,
					//         message: response.data.message,
					//         type: 'error'
					//     })
					//     // return
					// }

					Callback.call(this, response)
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error'
					})
				})
		}
		
		
		Vue.prototype.axiosPostFile = function(urls, params, Callback) {
			// if (localStorage.getItem(sy) == null || localStorage.getItem(sy) === undefined) {
			//     this.$router.push('/')
			//     return
			// }
			// 重复提交后台验证
			// if (sign !== undefined) {
			//     axios.defaults.headers.common['sign'] = sign
			// }
			// axios.defaults.headers.post['Content-Type'] = "multipart/form-data"
			
			
			// axios({
			// 	method:"post",
			// 	url:BaseURL + urls,
			// 	data:getToken(params)
			// })
			axios.post(BaseURL + urls, params,{headers: {'Content-Type': 'multipart/form-data'}})
				.then(response => {
					if (response.data.code == 501) {
						this.$message({
							showClose: true,
							message: response.data.message, //'token失效,重新登录',
							type: 'error'
						})
						this.history("/login")
						this.onLoad(false)
						return
					}
		
					if (response.data.code == 2) {
						this.$message({
							showClose: true,
							message: response.data.message,
							type: 'error'
						})
						this.$router.push("/")
						this.onLoad(false)
						return
					}
		
					// if (response.data.code != 0) {
					//     this.$message({
					//         showClose: true,
					//         message: response.data.message,
					//         type: 'error'
					//     })
					//     // return
					// }
		
					Callback.call(this, response)
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error'
					})
				})
		}
		

		Vue.prototype.axiosGets = function(urls, params, Callback) {
			// if (localStorage.getItem(sy) == null || localStorage.getItem(sy) == undefined) {
			//     this.$router.push("/")
			//     return
			// }
			axios.get(BaseURL + urls, getposys(params))
				.then(response => {

					if (response.data.code == 501) {
						this.$message({
							showClose: true,
							message: response.data.message, //'token失效,重新登录',
							type: 'error'
						})
						this.history("/login")
						this.onLoad(false)
						return
					}
					if (response.data.code == 2) {
						this.$message({
							showClose: true,
							message: response.data.message,
							type: 'error'
						})
						this.$router.push("/")
						this.onLoad(false)
						return
					}

					Callback.call(this, response)
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error'
					})
				})
		}


		//加载遮罩
		var loading = null
		Vue.prototype.onLoad = function(bool, Text) {
			if (bool) {
				var text = Text == undefined ? '加载中' : Text
				loading = this.$loading({
					lock: true,
					text: text,
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
			} else {
				loading.close()
			}
		}

		Vue.prototype.loginProject = function(params, Callback) {
			axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

			axios.post(BaseURL + "login", qs.stringify(params.admin))
				.then(response => {
					//填写错误代码


					Callback.call(this, response)
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error'
					})
				})
		}

		// Vue.prototype.PostTest = function (params, Callback) {
		//     // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
		//     axios.defaults.headers.common['Authorization'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBRVMiLCJhdWQiOjEsImlhdCI6MTU5NjUyMzEwNiwibmJmIjoxNTk2NTIzMTA3LCJleHAiOjE1OTcxMjc5MDYsImRhdGEiOnsidXNlcl9pZCI6MSwiaXNfYWRtaW4iOjEsInVzZXJfc3lzdGVtIjoyMjJ9fQ.y0idhgRH_2abIA9x3Xjj-xJ0J3moSAKwMwQItMyYejQ"
		//     axios.post("http://192.168.1.186:8085/index.php/admin/order/addOrder")
		//         .then(response => {
		//             //填写错误代码


		//             Callback.call(this, response)
		//         })
		//         .catch(error => { })
		// }



		//token异常状态
		Vue.prototype.noToken = -100

		//多选框宽度
		Vue.prototype.checkWidth = 55

		//page_size
		Vue.prototype.public_page_size = 99999999

		//手机号正则
		Vue.prototype.phoneReg = /^1[3456789]\d{9}$/
		Vue.prototype.passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
		Vue.prototype.passwordTips = "请输入6到20位的数字与字母组合"

		Vue.prototype.filterNumber = function(value) {
			return value.match(/\d/ig) ? value.match(/\d/ig).join('') : ''
		}

		Vue.prototype.filterMoney = function(value) {

			//开头禁止小数点
			if (value == '.') {
				return ''
			}
			if (value.indexOf('.') > 0) {
				let valArr = value.split('.')
				//禁止输入一个以上的小数点
				if (valArr.length > 2) {

					value = valArr[0] + '.' + valArr[1]
				}
				//保留两位小数
				if (valArr[1].length > 2) {
					value = valArr[0] + '.' + valArr[1].substring(-3, 2)
					// value = parseFloat(value).toFixed(2)
				}

			}
			let numArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
			/*  value.forEach(item=>{
			    console.log(item)
			  }) */
			let newVal = ''
			for (var i = 0; i < value.length; i++) {
				if (numArr.indexOf(value[i]) < 0) {
					newVal[i] = ""
				} else {
					newVal += value[i]
				}
			}
			return newVal
			// return value.match(/\d/ig) ? value.match(/\d/ig).join('') : ''
		}

		Vue.prototype.filterLetterNum = function(value) {
			return value.match(/\d|[A-Z]|[a-z]/ig) ? value.match(/\d|[A-Z]|[a-z]/ig).join('') : ''
		}

		Vue.prototype.imgVerifiy = function(file) {
			const isLt2M = file.size / 1024 / 1024 < 10
			if (!isLt2M) {
				this.msgError('上传文件大小不能超过 10MB!')
			}
			return isLt2M
		}
		//根据权限显示不同的目录和按钮
		Vue.prototype.isRole = function(pows) {
			if (pows == undefined) {
				return true
			}
			let Hraccess = JSON.parse(localStorage.getItem(this.sy)).role_info;
			let bool = false;
			pows.forEach(item => {
				
				if (Hraccess.indexOf(item) >= 0) {
					
					bool = true;
				}
			})
			return bool;
		}
		//请求异常处理
		Vue.prototype.error = function(data) {
			this.msgError(data.msg)
		}


		//
		Vue.prototype.getMonthDays = function(year, month) {
			var stratDate = new Date(year, month - 1, 1),
				endData = new Date(year, month, 1)
			var days = (endData - stratDate) / (1000 * 60 * 60 * 24)
			return days
		}

		//字符串转对象
		Vue.prototype.strToObj = function(str) {
			//在不同的地方调用方法的时候，变量的类型是不一样的，JSON.parse()里的参数只能是string类型
			return (typeof str) == "string" ? JSON.parse(str) : str
		}

		//根据年，月返回这个月的开始时间和结束时间
		Vue.prototype.returnMonthStartEnd = function(y, m) {
			let Start = new Date(y + '-' + m + '-01 00:00:00').valueOf()
			let End = null
			if (m != 12) {
				End = (new Date(y + '-' + (parseInt(m) + 1) + '-01 00:00:00').valueOf()) - 1
			} else {
				End = (new Date((parseInt(y) + 1) + '-01-01 00:00:00').valueOf()) - 1
			}
			return [Start, End]
		}


		//返回
		Vue.prototype.goBack = function() {
			window.history.go(-1)
		}
		//跳转
		Vue.prototype.history = function(url) {
			this.$router.push(url)
		}





		//时间转时间戳
		Vue.prototype.dateToStamp = function(date) {
			if (date == "" || date == undefined || date == null) {
				return ""
			}
			return parseInt(new Date(date).valueOf() / 1000)
		}

		//时间戳转时间
		Vue.prototype.stampToDate = function(shijianchuo, bool) {
			if (shijianchuo == "" || shijianchuo == undefined || shijianchuo == null) {
				return ""
			}
			//shijianchuo是整数，否则要parseInt转换
			let shijianchuoNew = shijianchuo
			if (("" + shijianchuo).length < 13) {
				shijianchuoNew = shijianchuo * 1000
			}
			var time = new Date(shijianchuoNew)
			var y = time.getFullYear()
			var m = time.getMonth() + 1
			var d = time.getDate()
			var h = time.getHours()
			var mm = time.getMinutes()
			var s = time.getSeconds()
			if (bool == undefined) {
				return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d)
			} else {
				return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' +
					(
						mm <
						10 ? '0' + mm : mm) + ':' + (s < 10 ? '0' + s : s)
			}

		}

		//时间戳转时间
		// Vue.prototype.formatDate = function (shijianchuo) { //形参
		//     // debugger
		//     if (shijianchuo == "" || shijianchuo == undefined || shijianchuo == null) {
		//         return "";
		//     }
		//     //shijianchuo是整数，否则要parseInt转换
		//     let shijianchuoNew = shijianchuo;
		//     if (("" + shijianchuo).length < 13) {
		//         shijianchuoNew = shijianchuo * 1000
		//     }
		//     var time = new Date(shijianchuoNew);
		//     var y = time.getFullYear();
		//     var m = time.getMonth() + 1;
		//     var d = time.getDate();
		//     var h = time.getHours();
		//     var mm = time.getMinutes();
		//     var s = time.getSeconds();
		//     return "" + y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (mm <
		//         10 ? '0' + mm : mm) + ':' + (s < 10 ? '0' + s : s);
		// }

		//消息提示
		Vue.prototype.msg = function(Text) {
			this.$message(Text)
		}

		//成功消息
		Vue.prototype.msgSuccess = function(Text) {
			this.$message({
				message: Text,
				type: 'success'
			})
		}

		//警告消息
		Vue.prototype.msgWarning = function(Text) {
			this.$message({
				message: Text,
				type: 'warning'
			})
		}

		//错误消息
		Vue.prototype.msgError = function(Text) {
			this.$message({
				message: Text,
				type: 'error'
			})
		}

	}
}
