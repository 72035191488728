import Vue from 'vue'
import App from './App.vue'
import store from "./store/index.js";
import router from "./router";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/base.less";

Vue.config.productionTip = false


//公用js
import base from '@/assets/js/base' //引用

// 分页组件
import Page from '@/views/public/page/page'

//过滤器
import filters from '@/filter/filter'

//富文本组件
import UE from '@/views/public/ue/UE'

//图片上传组件
import UploadImg from '@/views/public/uploadImg/uploadImg'
import 'babel-polyfill'

Vue.component('Page', Page)

Vue.use(base)

Vue.use(ElementUI)

Vue.component('UE', UE)

Vue.component('UploadImg', UploadImg)


/* 过滤器统一处理加载 */
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})


new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
