import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    product_name: ""
  }
  const mutations = {
      constProduct_name(state, value) {
          return (state.product_name = value)
      },
      
  }
  export default new Vuex.Store({
      state,
      mutations
  })
  
  
