<template>
	<!-- 建议不隐藏，隐藏后在一定条件下page=NaN-->
	<el-form-item :label="upImg.label">
		<!-- <el-input v-model="form.title" class="w_400"></el-input> -->
		<el-upload class="avatar-uploader" ref="img" :action="imgBaseURL" :show-file-list="false" :name="upImg.name" :before-upload="handleAvatarSuccess">
			<!-- {{imageUrl}} -->
			<img v-if="upImg.imageUrl" :src="upImg.imageUrl" class="avatar">
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			<div slot="tip" class="el-upload__tip">推荐尺寸：{{upImg.width}} x {{upImg.height}}</div>
		</el-upload>
	</el-form-item>
</template>

<script>
	export default {
		props: ['upImg'],
		data() {
			return {
				// upImg: {
				// 	label: '',
				// 	imageUrl: '',
				// 	name: '',
				// 	width: '',
				// 	height: '',
				// 	file: ''
				// }
			};
		},
		methods: {
			//图片上传
			handleAvatarSuccess(file) {
				console.log(file);
				this.upImg.file = file;
				let reader = new FileReader();
				reader.readAsDataURL(file)
				let that = this;
				reader.onload = function(evt) {
					// console.log(evt);
					that.upImg.imageUrl = evt.target.result;
					// console.log(this.imageUrl);
				}
				return false;
			},
		}
	};
</script>

<style>

</style>
